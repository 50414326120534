.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.outer {
    width: 1px;
    height: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;
}

.inner {
    position: absolute;
    width: 100%;
    height: 40%;
    background: grey;
    top: 30%;
    box-shadow: 0px 0px 30px 20px grey;
}

.footer {
    margin-top: 50rem;
    padding-top: 1rem;
    color: white;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    vertical-align: middle;
    z-index: 5;
}
